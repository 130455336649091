
import { fetchAuthSession } from 'aws-amplify/auth';

export async function _paylowHandler_Authenticator(request) {
    const session = await fetchAuthSession();
    return {
        token: session.tokens.idToken.toString(),
    };
}
export async function _paylowHandler_BankLinkRedirector(request) {
    console.log("[Handler] Redirecting to:", request.url);
    window.location.href = request.url;
}



export function loadPaylowCircletInterface(iframeId, allowedDomain) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = `${process.env.REACT_APP_CIRCLET_HOST}/assets/cdn/paylowCircletInterface.js`;
        script.async = true;

        script.onload = () => {
            try {
                if (!window.paylowCircletInterface) {
                    reject(new Error('Failed to load the PaylowCircletInterface script'));
                    return;
                }
                console.log("[Client] paylowInterface loaded");
                window.paylowCircletInterface.configure({
                    circletHost: process.env.REACT_APP_CIRCLET_HOST
                });
                resolve(window.paylowCircletInterface);
            } catch (error) {
                reject(error);
            }
        };

        script.onerror = () => {
            reject(new Error('Failed to load the PaylowCircletInterface script'));
        };
        document.body.appendChild(script);
    });
}

