import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home';
import Subscription from './components/subscriptions';
import { Authenticator, useTheme, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { Amplify } from 'aws-amplify';
import { _paylowHandler_Authenticator, _paylowHandler_BankLinkRedirector, loadPaylowCircletInterface } from './components/paylowService';
import { fetchAuthSession } from 'aws-amplify/auth';
import Layout from './components/Layout';
import Balance from './components/pages/Balance';
import Benefits from './components/pages/Benefits';
import Loans from './components/pages/Loans';
import './App.css'
import logo from './assets/images/paylow-logo-dark.png'
import { ColorProvider } from './components/colorContext';
import { ModeProvider } from './components/ModeContext';
import QueryParamTracker from './components/Test';
import { LogoProvider } from './components/LogoContext';

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: 'eu-central-1_HYmsTR6b2', // Replace with your actual User Pool ID
            userPoolClientId: '7pcmlrnpn6tofr7a3cb6v82u98', // Replace with your actual User Pool Client ID
            region: 'eu-central-1', // Replace with your region
        }
    },
});
const components = {
    Header() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <img
                    alt="Paylow logo"
                    src={logo}

                />
                <h5 className='under-logo-text'>Circlet Host Demo</h5>
            </View>
        );
    },

}
console.log('Amplify configuration:', Amplify.getConfig());

const App = () => {
    const [paylowCircletInterface, setPaylowCircletInterface] = useState(null);

    useEffect(() => {
        let isMounted = true;
        loadPaylowCircletInterface('', '')
            .then((interfaceInstance) => {
                if (isMounted) {
                    setPaylowCircletInterface(interfaceInstance);
                    interfaceInstance.registerAuthenticator(_paylowHandler_Authenticator);
                    interfaceInstance.registerBankLinkRedirector(_paylowHandler_BankLinkRedirector);
                }
            })
            .catch((error) => {
                console.error('Failed to initialize PaylowCircletInterface:', error);
            });

        return () => {
            isMounted = false;
            if (paylowCircletInterface) {
                // Cleanup if necessary
            }
        };
    }, []);
    return (
        <Authenticator className='amplify-container' components={components} hideSignUp>

            <Router>
                <LogoProvider>
                <ColorProvider>
                    <ModeProvider>
                <Layout>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/subscriptions" element={<Subscription paylowCircletInterface={paylowCircletInterface} />} />
                        <Route path="/about" element={<h1>About PayLow</h1>} />
                        <Route path='balance' element={<Balance />} />
                        <Route path='benefits' element={<Benefits />} />
                                <Route path='loans' element={<Loans />} />
                                {/* <Route path='test' element={<QueryParamTracker/>} /> */}
                    </Routes>
                        </Layout>
                        </ModeProvider>
                    </ColorProvider>
                </LogoProvider>
            </Router>
        </Authenticator>
    );
};

export default App;